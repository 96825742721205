<template>
  <div class="home-page animate__animated animate__fadeInUp">
    <Homepage/>
    <Expertise/>
  </div>
</template>

<script>
import Homepage from "@/components/Homepage";
import Expertise from "@/components/Expertise";
export default {
  name: 'Home',
  components: {
    Homepage,
    Expertise
  }
}
</script>
