import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/attorneys',
    name: 'Our Attorneys',
    component: () => import(/* webpackChunkName: "about" */ '../views/Attorneys.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
