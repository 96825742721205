<template>
  <v-container>
    <v-row>
      <v-col class="col-lg-9 col-md-6 col-sm-10">
        <div class="intro-paragraphs">
          <div class="intro-paragraphs__first text-lg-h1 text-h3 title-paragraph">
            {{titleParagraph}}
          </div>
          <div class="intro_paragraphs__second text-lg-h4 text-md-h6 font-weight-thin">
            {{descriptionParagraph}}
          </div>
        </div>
      </v-col>
      <v-col class="col-lg-3 col-md-6 col-sm-2">
        <div class="animate__animated animate__fadeIn">
          <v-img class="mx-auto d-block" src="@/assets/scalco-white.png"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Homepage",
  data() {
    return {
      titleParagraph: 'Scalco Attorneys Inc is an established boutique law firm operating in Northcliff, Johannesburg.',
      descriptionParagraph: 'We are a family-run practice with a focus on the fields of Family Law, Sectional Title Law, Estate Administration and Civil Litigation. We pride ourselves on being a friendly, approachable, and client-orientated firm, with a passion for the law and for providing affordable and high-quality legal services.'
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__first {
    padding-bottom: 1.5rem;
    color: #203049;
  }
}
</style>