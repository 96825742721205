<template>
  <v-app id="app">
    <v-app-bar id="app__nav" flat color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="toolbar-title" @click="$router.push('/')">Scalco Attorneys</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
        >
          <v-list-item :to="'/'">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item :to="'attorneys'">
            <v-list-item-title>Our Attorneys</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'contact'">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-footer color="#203049" padless>
      <v-col
        class="text-center scalco-footer"
        cols="12"
      >
        &copy; {{ new Date().getFullYear() }} Scalco Attorneys Inc
      </v-col>
    </v-footer>

  </v-app>
</template>
<script>
export default {
  data () {
    return {
      drawer: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scalco.scss';
#app {
  font-family: "Roboto", sans-serif;
  &__nav {
    border-top: $scalco-gold solid 0.3rem !important;
  }
}
.toolbar-title {
  cursor: pointer;
}
.scalco-footer {
  color: white;
  border-bottom: $scalco-gold solid 0.3rem !important;
}
</style>
