<template>
  <v-container class="scalco-expertise">
    <div class="scalco-component-spacer"></div>
    <div class="text-lg-h2 text-md-h5">{{componentHeader}}</div>
    <v-row>
      <v-col v-for="expertise in expertiseCards"
             :key="expertise.header"
             class="d-flex col-md-3 col-12">
        <div class="d-flex flex-column flex-fill scalco-expertise__card">
          <div class="scalco-expertise__header text-lg-h4 text-md-h6 font-weight-thin">{{expertise.header}}</div>
          <div>
            <ul class="scalco-expertise__list">
              <li v-for="option in expertise.options" :key="option" class="scalco-expertise__item">{{option}}</li>
            </ul>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Expertise",
  data() {
    return {
      componentHeader: 'Areas of Expertise',
      expertiseCards: [
        { header: 'Family Law', options: ['Divorce', 'Maintenance', 'Parental rights and responsibilities', 'Domestic violence', 'Children’s Court matters', 'Parenting Plans', 'Mediation', 'Drafting of Ante Nuptial Contracts']},
        { header: 'Wills and Estates', options: ['Drafting of Wills', 'Administration of Estates']},
        { header: 'Sectional Title Law', options: ['Sectional Title Disputes', 'Homeowner’s Association Disputes', 'Collection of unpaid levies']},
        { header: 'General Civil Litigation', options: ['High Court Litigation', 'Magistrates’ Court Litigation', 'Debt Collections', 'Motor Industry Disputes', 'Contractual Disputes']}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scalco.scss';
.scalco-expertise {
  &__card {
    padding-top: 1rem;
  }
  &__list {
    padding-top: 0.8rem;
  }
  &__header {
    padding: 0.5rem;
    border-bottom: $scalco-gold solid 0.1rem;
  }
  &__title {
    background-color: $scalco-gold;
  }
  &__item {
    padding: 0.15rem;
  }
}
</style>